
<template>

  <v-card flat class="transparent">
      <!-- <v-img src="@/assets/RABaseBG.jpeg"> -->
      <v-parallax class="recordparallax2"
    height="150"
        src="@/assets/RABaseBG.jpeg"
    >
    <v-row
          align="center"
          justify="center"
        >
    <v-col class="stdparallaxgradient text-center" cols="12">
      <h1 class="display-2 font-weight-thin mb-4">Dashboards</h1>
      <h4 class="headline">Dashboards</h4>
      
    </v-col>
  </v-row>
    </v-parallax>
               
      
 <v-layout class="justify-center" style="padding-top: 70px">

    <v-flex xs10 s10 md10 lg10>
        
        <v-card min-height="500" :dark="AppisDarkMode" :class="DashboardBGGrad" flat>
            <!-- <v-img src="@/assets/RABaseBG.jpeg"> -->
            <v-layout row class="justify-start mx-3"><span class="secondary--text headline" id="Tab3"> {{ActiveDashboard.Name}} Dashboard
              <v-menu
              :close-on-content-click="false"
                transition="scale-transition"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    mdi-menu-down
                  </v-icon>
                </template>
                <v-card>
                  <v-select :items="Dashboards" v-model="ActiveDashboard" item-text="Name" return-object>
                  </v-select>
                </v-card>
              </v-menu>
              </span></v-layout>
            <v-card-text>
                <component :is="ComputedActiveDashboard" :AppisDarkMode="AppisDarkMode" :UserRecord="UserRecord" :UserRoles="UserRoles" :UserBusinessUnitID="UserBusinessUnitID"/>
            </v-card-text>
             <!-- </v-img> -->
        </v-card>
    </v-flex>

 </v-layout>
      <!-- </v-img> -->
  </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'

export default {
  props: ['AppisDarkMode'],
    middleware: 'auth',
    components: {
    },
    data () {
        return {
            ActiveDashboard: '',
            Dashboards: [],          
            snackbar: false,
            helpsheet: false,
            helpnewsheet: false,            
            UserRecord: {},  
            
            UserRoles: [],         
            UserBusinessUnitID: '',

        }
    },
    computed: {
      userLoggedIn () {
      return this.$store.getters.user
    },
        ComputedActiveDashboard(){
          if(this.ActiveDashboard && this.ActiveDashboard.Number){
            return this.ActiveDashboard.Name+this.ActiveDashboard.Number.toString()
          }
          else{
            return this.ActiveDashboard
          }
      },
      DashboardBGGrad(){
        if(this.AppisDarkMode){
          return 'stdparallaxgradient'
        }
        else{
          return 'stdparallaxgradientinvert'
        }
      },
            filteredGroups() {
      
      return this.groups;
    }
    },

    created() {
    this.FocusedViewToggle()
      this.IntranetViewToggle()
      this.GetRequestingUser()
},
    methods: {
      GetRequestingUser(){
        var vm = this;
        if (vm.userLoggedIn) {
              vm.UserRecord = vm.userLoggedIn
              vm.UserRoles = userdetails.rolesarrayQuery
              vm.UserBusinessUnitID = userdetails.Business_Unitid
        }
      },
      
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',false)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
      ToggleHelp() {
        this.helpsheet = true
      },
      ToggleNewHelp() {
      
        this.helpnewsheet = true
      },
         
    
      },


}
</script>

<style>

</style>
    